const cards = [
   {
      id: "img01",
      target: "",
      alt: "Puffs",
      title: "Puffs",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Puff",
      title: "Puff",
      description: "",
   },
   {
      id: "img03",
      target: "",
      alt: "Puffs Garden",
      title: "Puffs Garden",
      description: "",
   },
   {
      id: "img04",
      target: "",
      alt: "Puffs",
      title: "Puffs",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Cordone Mesa Ramon Zancanaro",
      title: "Cordone Mesa Ramon Zancanaro",
      description: "",
   },
   {
      id: "img06",
      target: "",
      alt: "Puffs",
      title: "Puffs",
      description: "",
   },
]

export { cards }